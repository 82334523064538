<script setup lang="ts">
import type { Schemas } from "#shopware";

const props = withDefaults(
  defineProps<{
    product: Schemas["Product"];
    size?: "small" | "large";
    textTransform?: "" | "lowercase" | "uppercase" | "capitalize";
  }>(),
  {
    size: "large",
    textTransform: "",
  },
);
const { product, textTransform } = toRefs(props);

const {
  addToCartWithNotifications,
  quantity,
  incrementPurchaseQuantity,
  canIncrementPurchaseQuantity,
  decrementPurchaseQuantity,
  canDecrementPurchaseQuantity,
} = await useAddToCart(product);

const loading = ref(true);

const emit = defineEmits<{
  (e: "quantityChanged", quantity: number): void;
}>();

onMounted(() => {
  loading.value = false;
});

watch(quantity, (q) => emit("quantityChanged", q));
</script>

<template>
  <div
    v-if="product?.available || false"
    class="flex flex-row"
    :class="[size == 'large' ? 'h-12.5' : 'h-10']"
    @click.prevent="true"
  >
    <div
      class="basis-1/4 relative bg-scheppach-shades-0 rd-1 b-scheppach-primary-300 b px-2 flex gap-2 items-center c-scheppach-primary-500"
    >
      <div
        class="w-3 i-sl-subtract h-full"
        :class="{
          'c-scheppach-neutral-300': !canDecrementPurchaseQuantity,
          'cursor-pointer': canDecrementPurchaseQuantity,
        }"
        @click.prevent="decrementPurchaseQuantity"
      ></div>
      <div class="grow text-center">
        <!-- TODO kept around for manual mode
          <input
            id="qty"
            v-model="quantity"
            type="number"
            :min="product.minPurchase || 1"
            :max="product.calculatedMaxPurchase"
            :step="product.purchaseSteps || 1"
            class="border rounded-md py-2 px-4 border-solid border-1 border-cyan-600 w-full"
            data-testid="product-quantity"
          />
        -->
        <div class="font-700">{{ quantity }}</div>
        <div class="text-3" :class="{ hidden: size == 'small' }">
          {{ $t("product.amount") }}
        </div>
      </div>
      <div
        class="w-3 i-sl-add h-full cursor-pointer"
        :class="{
          'c-scheppach-neutral-300': !canIncrementPurchaseQuantity,
          'cursor-pointer': canDecrementPurchaseQuantity,
        }"
        @click.prevent="incrementPurchaseQuantity"
      ></div>
    </div>
    <div class="basis-3/4 ml-4">
      <button
        class="w-full bg-scheppach-primary-500 hover:bg-scheppach-primary-700 h-full text-scheppach-shades-0 font-700 rd-1"
        :class="[textTransform]"
        data-testid="add-to-cart-button"
        :disabled="loading"
        arial-label="Add to Cart"
        @click.prevent="addToCartWithNotifications"
      >
        <span class="px-3" :class="{ hidden: size == 'small' }">
          {{ $t("product.addToCart") }}
        </span>
        <span
          class="i-sl-shopping-cart-empty-1 inline-block mt-1 px-6"
          :class="{ hidden: size == 'large' }"
        ></span>
      </button>
    </div>
  </div>
</template>
